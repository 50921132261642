<template>
   <div class="min-h-screen bg-gray-100 flex flex-col justify-center">
    <div class="md:mx-auto md:w-full px-4 md:px-0 md:max-w-4xl">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-8">
          <h2 class="font-semibold text-2xl text-gray-800 pb-4">Alterar senha</h2>
          <label class="font-semibold text-base text-gray-800 pb-2 block">Digite sua nova senha e confirme</label>
          <input v-model="form.password" placeholder="Senha" type="password" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mt-1 mb-3 text-base w-full" />
          <input v-model="form.confirmpassword" placeholder="Confirmação de senha" type="password" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mt-1 mb-3 text-base w-full" />
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-8">
            </div>
            <div class="col-span-12 md:col-span-4">
              <button @click="alterarSenha" type="button" class="transition duration-200 bg-gray-900 hover:bg-gray-800 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white w-full py-4 text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Continuar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-4 justify-self-center hidden md:block">
          <img class="md:mt-16" :src="require('../../assets/logo.png')" width="200" />
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'EsqueciSenha',
  data: function () {
    return {
      option: 'consultor',
      form: {
        password: '',
        confirmpassword: ''
      },
    }
  },
  methods: {

    async alterarSenha() {
        if(this.form.password !== this.form.confirmpassword) return this.$vToastify.error("Senhas não correspondem");

        this.form.query = this.$route.query;
        this.form._id = this.$route.params.id;

        const resp = await this.$http.post(`/alterar-senha`, this.form); 

        if (!resp.data.success) {
            this.$vToastify.error(resp.data.err);
        } else {
            this.$vToastify.success("Senha alterada com sucesso!");
            this.$router.push({path:'/login'});
        }
       
    }
  },
}
</script>
